<template>
  <div>
    <JourneyAddEdit
      v-model="currentJourney"
      @save="saveJourney"
    />
  </div>
</template>

<script>
import JourneyAddEdit from '@/modules/journey/components/JourneyAddEdit.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('journey')

export default {
  components: {
    JourneyAddEdit,
  },
  data() {
    return {
      currentJourney: { name: 'new journey' },
    }
  },
  methods: {
    saveCohort({ data, close }) {
      this.createJourney(data).then(() => {
        if (close) {
          this.$router.push({ name: 'second-page' })
        }
      }, () => {
      })
    },
    ...mapActions(['createJourney']),
  },
}
</script>
